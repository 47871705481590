import React from 'react'
import SiteLayout from '../components/SiteLayout'
import SiteSearch from '../components/SiteSearch'
import { Row } from 'antd'
import { StaticQuery, graphql } from "gatsby"

import Not_Found_Img from '../images/404-image.png'

const NotFoundPage = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

        <div className="flex-item not_found">
          <h1>404 Page not found</h1>
          <p>We are sorry, we do not have the page you requested.</p>
          <p>You can go to the <a href="/">homepage</a> or try the search form to find what you were looking for.</p>
          <StaticQuery
            query={graphql`
                query SearchIndexQuery {
                  siteSearchIndex {
                    index
                  }
                }
              `}
            render={data => (
              <header>
                <SiteSearch searchIndex={data.siteSearchIndex.index} />
              </header>
            )}
          />
          <p><a href="https://strands.com/company/contact/">Contact us</a> if you still need assistance. We are glad to help!</p>
        </div>

        <div className="flex-item not_found">
          <img src={Not_Found_Img} alt="Lorem ipsum dolor sit amet..." />
        </div>

      </div>
    </Row>
  </SiteLayout>
)

export default NotFoundPage